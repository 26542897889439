import React from "react"
import Header from "../Header/index"
import Footer from "../Footer/index"
import CookieConsent from "../CookieConsent"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import StoryblokService from '../../utils/storyblok-service'
import AnalyticsService from "../../utils/analytics-service"

import "./reset.scss"
import "./styles.scss"
import MarketingService from "../../utils/marketing-service"

export default function Layout({ children, location }){
  const { settings } = useStaticQuery(graphql`
  query Settings {
    settings: allStoryblokEntry(filter: {field_component: {eq: "settings"}}) {
      edges {
        node {
          name
          full_slug
          content
        }
      }
    }
  } 
  `)

  let activeLanguage = null

  if (typeof location !== "undefined") {
    let { pathname } = location
    let language = pathname.split("/")[1].replace("/", "")
    activeLanguage = ["de", "en"].includes(language) ? language : "de"
  } else {
    activeLanguage = "de"
  }

  let correctSetting = settings.edges.filter(edge => edge.node.full_slug.indexOf(activeLanguage) > -1)
  let hasSetting = correctSetting && correctSetting.length ? correctSetting[0].node : {}
  let content = typeof hasSetting.content === "string" ? JSON.parse(hasSetting.content) : hasSetting.content
  let parsedSetting = Object.assign({}, content, {content: content})

  return (
    <div>
      <Helmet
          script={[
            {
              "src": `//app.storyblok.com/f/storyblok-latest.js?t=${StoryblokService.token}`,
              "type": "text/javascript",
              "defer": "defer"
            }
          ]}
      />
      <Helmet
          script={[
            {
              "innerHTML": `var StoryblokCacheVersion = "${StoryblokService.getCacheVersion()}";`,
              "type": "text/javascript"
            }
          ]}
      />
      <Header settings={parsedSetting} lang={activeLanguage} location={location} />
      <main>
      { children }
      </main>
      <Footer settings={parsedSetting} />
      <CookieConsent onAcceptTracking={() => {
        const analyticsService = new AnalyticsService()
        analyticsService.initialize()
        if (typeof window !== "undefined") {
          analyticsService.trackPage(window.location.pathname)
        }
      }} onAcceptMarketing={() => {
        const marketingService = new MarketingService()
        marketingService.initialize()
        marketingService.trackPage()
      }} />
    </div>
  )
}