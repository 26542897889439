const extractLocales = (storyblokAlternatives) => {
    const locales = []
    for (const locale of storyblokAlternatives) {
      const lang = locale.full_slug.split('/')[0]
      let path = locale.full_slug.substring(3)

      if (path === 'home') {
        path = ''
      }

      locales.push({
        lang,
        path
      })
    }

    return locales
}

export default extractLocales