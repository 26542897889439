import React from "react"

import "./styles.scss"

const Placeholder = ({componentName}) => (
  <div>
    <p>The component <strong>{componentName}</strong> has not been created yet.</p>
  </div>
)

export default Placeholder