import React, { Component } from "react"
import SbEditable from "storyblok-react"

import "./styles.scss"


class Headline extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
        this.level = this.blok.level
    }

    render() {
        let headline
        switch(this.level) {
            case "h1":
                headline = (<h1>{this.blok.text}</h1>)
                break
            case "h2":
                headline = (<h2>{this.blok.text}</h2>)
                break
            case "h3":
                headline = (<h3>{this.blok.text}</h3>)
                break
            case "h4":
                headline = (<h4>{this.blok.text}</h4>)
                break
            case "h5":
                headline = (<h5>{this.blok.text}</h5>)
                break
            case "h6":
                headline = (<h6>{this.blok.text}</h6>)
                break
            default:
                headline = (<h1>{this.blok.text}</h1>)
                break
        }
        return (
            <SbEditable content={this.blok}>
                <div className="headline-content">
                    {headline}
                </div>
            </SbEditable>
        )
    }
}  
  
export default Headline