import React, { Component } from "react"
import SbEditable from "storyblok-react"
import StoryblokService from "../../../utils/storyblok-service"

import "./styles.scss"


class Text extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        return (
            <SbEditable content={this.blok}>
                <div className="text-content" dangerouslySetInnerHTML={{ __html: StoryblokService.client.richTextResolver.render(this.blok.text)}} />
            </SbEditable>
        )
    }
}  
  
export default Text