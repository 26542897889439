import React, { Component } from "react"
import SbEditable from "storyblok-react"
import ImagesService from '../../../utils/images-service'

import "./styles.scss"


class Video extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        return (
            <SbEditable content={this.blok}>
                <div className="video-content">
                    {this.blok.autoplay &&
                    <video poster={ImagesService(this.blok.image.filename, true, { path: '1392x784/smart/filters:format(jpeg):quality(60)'})} controls="controls" autoplay="autoplay">
                        {this.blok.files.map((file, i) => {
                        return (<source src={file.url} type={file.type} />)
                        })}
                        <track kind="captions" srclang={this.blok.subtitle_language} src={this.blok.subtitle_file} />
                    </video>
                    }
                    {!this.blok.autoplay &&
                    <video poster={ImagesService(this.blok.image.filename, true, { path: '1392x784/smart/filters:format(jpeg):quality(60)'})} controls="controls">
                        {this.blok.files.map((file, i) => {
                        return (<source key={i} src={file.url} type={file.type} />)
                        })}
                        <track kind="captions" srcLang={this.blok.subtitle_language} src={this.blok.subtitle_file} />
                    </video>
                    }
                </div>
            </SbEditable>
        )
    }
}  
  
export default Video