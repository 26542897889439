import React, { Component } from "react"
import SbEditable from "storyblok-react"
import Author from "../../Author"
import Button from "../../Button"

import "./styles.scss"

import { withTranslation } from "react-i18next"


class Contact extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
        this.t = props.t
        this.author = props.blok.profile.content
    }

    render() {
        return (
            <SbEditable content={this.blok}>
                {this.blok.large &&
                <div className="contact-content large">
                    <h3>{this.blok.headline}</h3>
                    <p>{this.blok.text}</p>
                    <div className="profile-contact">
                        <Author profile={this.author} isLarge={this.blok.large} />
                        <Button type="primary" to={`/${ this.blok.link.cached_url }`} label={this.t("contact.button.label")} title={this.t("contact.button.title")} />
                    </div>
                </div>
                }
                {!this.blok.large &&
                <div className="contact-content">
                    <h3>{this.blok.headline}</h3>
                    <p>{this.blok.text}</p>
                    <div className="profile-contact">
                        <Author profile={this.author} isLarge={this.blok.large} />
                        <Button type="primary" to={`/${ this.blok.link.cached_url }`} label={this.t("contact.button.label")} title={this.t("contact.button.title")} />
                    </div>
                </div>
                }
            </SbEditable>
        )
    }
}  
  
export default withTranslation()(Contact)
