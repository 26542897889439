import React, { Component } from "react"
import SbEditable from "storyblok-react"

import "./styles.scss"


class Quote extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        return (
            <SbEditable content={this.blok}>
                <div className="quote-content">
                    <blockquote>
                        <div>
                            <p>{this.blok.text}</p>
                        </div>
                        <footer>
                            <cite>– {this.blok.source}</cite>
                        </footer>
                    </blockquote>
                </div>
            </SbEditable>
        )
    }
}  
  
export default Quote