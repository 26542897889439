import React, { Component } from "react"
import ImagesService from '../../utils/images-service'
import wobble from './wobble.svg'

import "./styles.scss"

import { withTranslation } from "react-i18next"

class Author extends Component {
    constructor(props) {
        super(props)
        this.t = props.t
        this.isLarge = props.isLarge || false
        this.author = props.profile
    }

    render() {
        return (
            <div>
                {this.isLarge &&
                <div className="author-large">
                <div className="avatar">
                    <picture>
                        <source srcSet={`${ ImagesService(this.author.image.filename, true, { path: '125x125/smart/filters:format(webp)'}) } 1x, ${ ImagesService(this.author.image.filename, true, { path: '250x250/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                        <source srcSet={`${ ImagesService(this.author.image.filename, true, { path: '125x125/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(this.author.image.filename, true, { path: '250x250/smart/filters:format(jpeg):quality(60)'}) } 2x`} type="image/jpeg" />
                        <img className="profile-picture" loading="lazy" src={ImagesService(this.author.image.filename, true, { path: '56x56/filters:format(jpeg):quality(60)'})} srcSet={`${ ImagesService(this.author.image.filename, true, { path: '125x125/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(this.author.image.filename, true, { path: '250x250/filters:format(jpeg):quality(60)'}) } 2x`} alt={this.author.image.alt} width="125" height="125" />
                    </picture>
                    <object className="wobble" type="image/svg+xml" data={wobble} alt="conduco labs Wobble" width="152" height="152" aria-labelledby="conduco labs Wobble"></object>
                </div>
                <div className="name">
                    <span className="h3 first-lastname">{this.author.firstname} {this.author.lastname}</span>
                    <span className="job-title">{this.author.jobTitle}</span>
                </div>
            </div>
                }
                {!this.isLarge &&
                <div className="author">
                    <div className="avatar">
                        <picture>
                            <source srcSet={`${ ImagesService(this.author.image.filename, true, { path: '56x56/smart/filters:format(webp)'}) } 1x, ${ ImagesService(this.author.image.filename, true, { path: '112x112/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                            <source srcSet={`${ ImagesService(this.author.image.filename, true, { path: '56x56/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(this.author.image.filename, true, { path: '112x112/smart/filters:format(jpeg):quality(60)'}) } 2x`} type="image/jpeg" />
                            <img className="profile-picture" loading="lazy" src={ImagesService(this.author.image.filename, true, { path: '56x56/filters:format(jpeg):quality(60)'})} srcSet={`${ ImagesService(this.author.image.filename, true, { path: '56x56/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(this.author.image.filename, true, { path: '112x112/filters:format(jpeg):quality(60)'}) } 2x`} alt={this.author.image.alt} width="56" height="56" />
                        </picture>
                        <object className="wobble" type="image/svg+xml" data={wobble} alt="conduco labs Wobble" width="68" height="68" aria-labelledby="conduco labs Wobble"></object>
                    </div>
                    <div className="name">
                        <span className="h3 first-lastname">{this.author.firstname} {this.author.lastname}</span>
                        <span className="job-title">{this.author.jobTitle}</span>
                    </div>
                </div>
                }
            </div>
        )
    }
}  
  
export default withTranslation()(Author)