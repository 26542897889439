import React from "react"
import Column from "../Column"
import SbEditable from "storyblok-react"

import "./styles.scss"

const Grid = ({blok}) => {
    let spacingClass = "no-spacing"
    let levelingClass = "level-top"

    if (blok.with_spacing) {
        spacingClass = "with-spacing"
    }

    if (blok.level_horizontally) {
        levelingClass = "level-center"
    }

    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className={`content-grid ${spacingClass} ${levelingClass}`}>
                <div className="container">
                    {blok.columns.map((blok) => (
                        <Column key={blok._uid} blok={blok} />
                    ))}
                </div>
            </div>
        </SbEditable>
        )
    }

export default Grid