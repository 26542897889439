import React, { Component } from "react"
import SbEditable from "storyblok-react"
import StoryblokService from "../../../utils/storyblok-service"

import "./styles.scss"


class CenteredText extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        return (
            <SbEditable content={this.blok}>
                <div className="centered-text-block">
                    <div className="text-container">
                        {this.blok.subheadline !== "" &&
                            <h3>{this.blok.subheadline}</h3>
                        }
                        {this.blok.headline !== "" &&
                            <h2 className="h1">{this.blok.headline}</h2>
                        }
                        {this.blok.text !== "" &&
                            <p>{this.blok.text}</p>
                        }
                    </div>
                </div>
            </SbEditable>
        )
    }
}  
  
export default CenteredText
