import React, { Component } from "react"
import SbEditable from "storyblok-react"
import Button from "../../Button"

class ButtonCT extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        return (
            <SbEditable content={this.blok}>
                <Button type={this.blok.type} to={this.blok.url} label={this.blok.label} title={this.blok.label} />
            </SbEditable>
        )
    }
}  
  
export default ButtonCT