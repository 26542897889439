import React, { Component } from "react"
import SbEditable from "storyblok-react"
import Item from "./Item"

import ImagesService from '../../../utils/images-service'

import "./styles.scss"


class Manifest extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        return (
            <SbEditable content={this.blok}>
                <div className="manifest-content">
                    <div className="container">
                        <h3>{this.blok.subtitle}</h3>
                        {this.blok.image.filename !== "" &&
                        <div className="image">
                            <img loading="lazy" src={ImagesService(this.blok.image.filename, false)} alt={this.blok.image.alt} />
                        </div>
                        }
                        <div className="manifest-items">
                            {this.blok.items.map((item, index) => (
                            <Item key={index} blok={item} />
                            ))}
                        </div>
                    </div>
                </div>
            </SbEditable>
        )
    }
}  
  
export default Manifest