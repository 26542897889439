import React, { Component } from "react"
import SbEditable from "storyblok-react"
import Item from "./Item"

import "./styles.scss"

import { withTranslation } from "react-i18next"


class Comparison extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
        this.t = props.t
    }

    render() {
        return (
            <SbEditable content={this.blok}>
                <div className="comparison-content">
                    <div className="header">
                        <div className="pro">{this.t("comparison.pro")}</div>
                        <div className="con">{this.t("comparison.contra")}</div>
                    </div>
                    {this.blok.items.map((item, i) => (
                    <Item key={i} blok={item} />
                    ))}
                </div>
            </SbEditable>
        )
    }
}  
  
export default withTranslation()(Comparison)