import React, { Component } from "react"
import SbEditable from "storyblok-react"

class Item extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        return (
            <SbEditable content={this.blok}>
                <div className="item">
                    <div className="pro">{this.blok.pro}</div>
                    <div className="con">{this.blok.contra}</div>
                </div>
            </SbEditable>
        )
    }
}  
  
export default Item