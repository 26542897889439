import React, { Component } from "react"
import SbEditable from "storyblok-react"

import "./styles.scss"


class Audio extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        return (
            <SbEditable content={this.blok}>
                <div className="audio-content">
                    {this.blok.autoplay &&
                    <audio controls="controls" autoplay="autoplay">
                        {this.blok.files.map((file, i) => {
                        return (<source src={file.url} type={file.type} />)
                        })}
                        <track kind="captions" srclang={this.blok.subtitle_language} src={this.blok.subtitle_file} />
                    </audio>
                    }
                    {!this.blok.autoplay &&
                    <audio controls="controls">
                        {this.blok.files.map((file, i) => {
                        return (<source key={i} src={file.url} type={file.type} />)
                        })}
                        <track kind="captions" srcLang={this.blok.subtitle_language} src={this.blok.subtitle_file} />
                    </audio>
                    }
                </div>
            </SbEditable>
        )
    }
}  
  
export default Audio