import React, { Component } from "react"
import SbEditable from "storyblok-react"
import Feature from "./Feature"

import "./styles.scss"


class Features extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        return (
            <SbEditable content={this.blok}>
                <div className="features-content">
                    {this.blok.features.map((feature, i) => (
                    <Feature key={i} blok={feature} />
                    ))}
                </div>
            </SbEditable>
        )
    }
}  
  
export default Features