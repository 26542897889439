import React from "react"
import Placeholder from "../Placeholder/index"

import Headline from "../ContentTypes/Headline"
import Image from "../ContentTypes/Image"
import Quote from "../ContentTypes/Quote"
import Text from "../ContentTypes/Text"
import SocialEmbed from "../ContentTypes/SocialEmbed"
import Video from "../ContentTypes/Video"
import Audio from "../ContentTypes/Audio"
import Hero from "../ContentTypes/Hero"
import Grid from "../ContentTypes/Grid"
import Illustration from "../ContentTypes/Illustration"
import Button from "../ContentTypes/Button"
import CenteredText from "../ContentTypes/CenteredText"
import Features from "../ContentTypes/Features"
import Comparison from "../ContentTypes/Comparison"
import Contact from "../ContentTypes/Contact"
import Manifest from "../ContentTypes/Manifest"
import Customers from "../ContentTypes/Customers"

const Components = {
  "grid": Grid,
  "headline": Headline,
  "image": Image,
  "text": Text,
  "quote": Quote,
  "socialEmbed": SocialEmbed,
  "video": Video,
  "audio": Audio,
  "hero": Hero,
  "illustration": Illustration,
  "button": Button,
  "centeredText": CenteredText,
  "features": Features,
  "comparison": Comparison,
  "contact": Contact,
  "manifest": Manifest,
  "customers": Customers
}

const Component = ({blok}) => {
  if (typeof Components[blok.component] !== "undefined") {
    const Component = Components[blok.component]
    return <Component blok={blok} />
  }
  return blok.component ? <Placeholder componentName={blok.component}/> : null
}

export default Component