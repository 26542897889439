module.exports = {
  siteMetadata: {
    title: `conduco labs`,
    description: `At conduco labs, we help startups and enterprises to design, launch, validate and accelerate customer-based digital platforms for any device.`,
    author: `@conducolabs`,
    siteUrl: `https://condu.co`,
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: "gatsby-plugin-sass",
      options: {
        useResolveUrlLoader: true,
      },
    },
    {
      resolve: `gatsby-plugin-webfonts`,
      options: {
        fonts: {
          google: [
            {
              family: `Barlow`,
              variants: [`400`, `600`, `800`],
              strategy: `selfHosted`
            },
            {
              family: `Material Icons`,
              strategy: `selfHosted`
            }
          ],
        }
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `conduco labs`,
        short_name: `conduco labs`,
        display: `standalone`,
        start_url: `/`,
        background_color: `#ffffff`,
        theme_color: `#30FFCA`,
        icon: `src/images/conducolabs-icon.png`,
      }
    },
    {
      resolve: 'gatsby-source-storyblok',
      options: {
        accessToken: 'zsrzynjrqaPxub9rcKu4Ygtt',
        homeSlug: 'home',
        version: process.env.NODE_ENV === 'production' ? 'published' : 'draft',
        resolveRelations: ['blogpost.author', 'blogpost.relatedPosts', 'settings.top_story', 'contact.profile']
      }
    },
    {
      resolve: `gatsby-plugin-i18n`,
      options: {
        langKeyDefault: 'de',
        langKeyForNull: 'en',
        prefixDefault: true,
        useLangKeyLayout: false
      },
    },
    {
      resolve: `gatsby-plugin-sitemap`,
      options: {
        exclude: [`/`]
      }
    },
    {
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        resolveEnv: () => process.env.GATSBY_ENV,
        env: {
          development: {
            policy: [{ userAgent: '*', disallow: ['/'] }]
          },
          production: {
            policy: [{ userAgent: '*', allow: '/' }]
          }
        }
      }
    }, {
      resolve: 'gatsby-plugin-remove-generator',
      options: {
        content: 'conducolabs/website-generator'
      }
    }
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
  ],
}


