import React from "react"
import SbEditable from "storyblok-react"
import { useSelector, useDispatch } from "react-redux"
import Cookies from "js-cookie"
import { enableEmbedCookies, disableEmbedCookies } from "../../../utils/store/actions"
import Switch from "../../Switch"

import "./styles.scss"

import { withTranslation } from "react-i18next"

function SocialEmbed(props) {
    const renderExternalPlugins = useSelector(state => state.embedCookies)
    const dispatch = useDispatch()

    const enableEmbed = () => {
        dispatch(enableEmbedCookies())
        Cookies.set("allowEmbedCookies", true, { path: "/", expires: 30 })
    }

    const disableEmbed = () => {
        dispatch(disableEmbedCookies())
        Cookies.set("allowEmbedCookies", false, { path: "/", expires: 30 })
        if (typeof document !== "undefined") {
            if (document.getElementById("twitterWidget")) {
                document.getElementById("twitterWidget").remove()
            }
            if (document.getElementById("instagramWidget")) {
                document.getElementById("instagramWidget").remove()
            }
        }
    }


    if (props.blok.type === "twitter" && renderExternalPlugins) {
        if (typeof document !== "undefined" && !document.getElementById("twitterWidget")) {
            const script = document.createElement("script")
            script.id = "twitterWidget"
            script.src = "https://platform.twitter.com/widgets.js"
            script.async = true
            document.body.appendChild(script)
        }
        return (
            <SbEditable content={props.blok}>
                <div className="social-embed-content">
                    <div className="twitter" dangerouslySetInnerHTML={{__html: props.blok.snippet}} />
                    <div className="toggle-external-content">
                        <Switch title={props.t("externalEmbed.label")} isEnabled={true} onEnabled={enableEmbed} onDisabled={disableEmbed} />
                        <div className="label">{props.t("externalEmbed.label")}</div>
                    </div>
                </div>
            </SbEditable>
        )
    } else if (props.blok.type === "instagram" && renderExternalPlugins) {
        if (typeof document !== "undefined" && !document.getElementById("instagramWidget")) {
            const script = document.createElement("script")
            script.id = "instagramWidget"
            script.src = "https://www.instagram.com/embed.js"
            script.async = true
            document.body.appendChild(script)
            document.getElementById("instagramWidget").addEventListener("load", function() {
                instgrm.Embeds.process() // eslint-disable-line no-undef
              });
        } else if (typeof instgrm !== "undefined") { // eslint-disable-line no-undef
            instgrm.Embeds.process() // eslint-disable-line no-undef
        }
        return (
            <SbEditable content={props.blok}>
                <div className="social-embed-content">
                    <div className="instagram" dangerouslySetInnerHTML={{__html: props.blok.snippet}} />
                    <div className="toggle-external-content">
                        <Switch title={props.t("externalEmbed.label")} isEnabled={true} onEnabled={enableEmbed} onDisabled={disableEmbed} />
                        <div className="label">{props.t("externalEmbed.label")}</div>
                    </div>
                </div>
            </SbEditable>
        )
    } else if (props.blok.type === "facebook" && renderExternalPlugins) {
        return (
            <SbEditable content={props.blok}>
                <div className="social-embed-content">
                    <div className="facebook" dangerouslySetInnerHTML={{__html: props.blok.snippet}} />
                    <div className="toggle-external-content">
                        <Switch title={props.t("externalEmbed.label")} isEnabled={true} onEnabled={enableEmbed} onDisabled={disableEmbed} />
                        <div className="label">{props.t("externalEmbed.label")}</div>
                    </div>
                </div>
            </SbEditable>
        )
    } else if (props.blok.type === "youtube" && renderExternalPlugins) {
        return (
            <SbEditable content={props.blok}>
                <div className="social-embed-content">
                    <div className="youtube" dangerouslySetInnerHTML={{__html: props.blok.snippet}} />
                    <div className="toggle-external-content">
                        <Switch title={props.t("externalEmbed.label")} isEnabled={true} onEnabled={enableEmbed} onDisabled={disableEmbed} />
                        <div className="label">{props.t("externalEmbed.label")}</div>
                    </div>
                </div>
            </SbEditable>
        )
    } else if (props.blok.type === "spotify" && renderExternalPlugins) {
        return (
            <SbEditable content={props.blok}>
                <div className="social-embed-content">
                    <div className="spotify" dangerouslySetInnerHTML={{__html: props.blok.snippet}} />
                    <div className="toggle-external-content">
                        <Switch title={props.t("externalEmbed.label")} isEnabled={true} onEnabled={enableEmbed} onDisabled={disableEmbed} />
                        <div className="label">{props.t("externalEmbed.label")}</div>
                    </div>
                </div>
            </SbEditable>
        )
    } else if (props.blok.type === "soundcloud" && renderExternalPlugins) {
        return (
            <SbEditable content={props.blok}>
                <div className="social-embed-content">
                    <div className="soundcloud" dangerouslySetInnerHTML={{__html: props.blok.snippet}} />
                    <div className="toggle-external-content">
                        <Switch title={props.t("externalEmbed.label")} isEnabled={true} onEnabled={enableEmbed} onDisabled={disableEmbed} />
                        <div className="label">{props.t("externalEmbed.label")}</div>
                    </div>
                </div>
            </SbEditable>
        )
    } else if (!renderExternalPlugins) { 
        return (
            <SbEditable content={props.blok}>
                <div className="social-embed-content no-permission">
                    <h3>{props.t("externalEmbed.headline")}</h3>
                    <p>{props.t("externalEmbed.description")}</p>
                    <div className="toggle-external-content">
                        <Switch title={props.t("externalEmbed.label")} isEnabled={false} onEnabled={enableEmbed} onDisabled={disableEmbed} />
                        <div className="label">{props.t("externalEmbed.label")}</div>
                    </div>
                    <p className="legal">{props.t("externalEmbed.legal")}</p>
                </div>
            </SbEditable>
        )
    } else {
        return (
            <div></div>
        )
    }
}

export default withTranslation()(SocialEmbed)