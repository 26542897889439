export const hasUnsafedCookieChanges = () => {
    return {
        type: "SAFED_COOKIES"
    }
}

export const hasNoUnsafedCookieChanges = () => {
    return {
        type: "UNSAFED_COOKIES"
    }
}

export const enableBasicCookies = () => {
    return {
        type: "ENABLE_BASIC_COOKIES"
    }
}

export const disableBasicCookies = () => {
    return {
        type: "DISABLE_BASIC_COOKIES"
    }
}

export const enableEmbedCookies = () => {
    return {
        type: "ENABLE_EMBED_COOKIES"
    }
}

export const disableEmbedCookies = () => {
    return {
        type: "DISABLE_EMBED_COOKIES"
    }
}

export const enableMarketingCookies = () => {
    return {
        type: "ENABLE_MARKETING_COOKIES"
    }
}

export const disableMarketingCookies = () => {
    return {
        type: "DISABLE_MARKETING_COOKIES"
    }
}

export const enableTrackingCookies = () => {
    return {
        type: "ENABLE_TRACKING_COOKIES"
    }
}

export const disableTrackingCookies = () => {
    return {
        type: "DISABLE_TRACKING_COOKIES"
    }
}