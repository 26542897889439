import React, { Component } from "react"
import SbEditable from "storyblok-react"
import wobble from './wobble.svg'


class Feature extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        return (
            <SbEditable content={this.blok}>
                <div className="feature-content">
                    <div className="feature-icon">
                        <div className="icon">
                            <i className="material-icons">{this.blok.icon}</i>
                        </div>
                        <object className="wobble" type="image/svg+xml" data={wobble} alt="conduco labs Wobble" width="68" height="68" aria-labelledby="conduco labs Wobble"></object>
                    </div>
                    <div className="feature-text">
                        <h2>{this.blok.title}</h2>
                        <p>{this.blok.text}</p>
                    </div>
                </div>
            </SbEditable>
        )
    }
}  
  
export default Feature