import React, { Component } from "react"
import SbEditable from "storyblok-react"
import ImagesService from '../../../utils/images-service'

import "./styles.scss"

import { withTranslation } from "react-i18next"


class Image extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
        this.t = props.t
    }

    render() {
        return (
            <SbEditable content={this.blok}>
                <div className="image-content">
                    <figure>
                        <picture>
                            <source media="(max-width: 450px)" srcSet={`${ ImagesService(this.blok.image.filename, true, { path: '450x338/smart/filters:format(webp)'}) } 1x, ${ ImagesService(this.blok.image.filename, true, { path: '900x676/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                            <source media="(max-width: 450px)" srcSet={`${ ImagesService(this.blok.image.filename, true, { path: '450x338/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(this.blok.image.filename, true, { path: '900x676/smart/filters:format(jpeg)'}) } 2x`} type="image/jpeg" />
                            <source srcSet={`${ ImagesService(this.blok.image.filename, true, { path: '696x392/smart/filters:format(webp)'}) } 1x, ${ ImagesService(this.blok.image.filename, true, { path: '1392x784/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                            <source srcSet={`${ ImagesService(this.blok.image.filename, true, { path: '696x392/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(this.blok.image.filename, true, { path: '1392x784/smart/filters:format(jpeg):quality(60)'}) } 2x`} type="image/jpeg" />
                            <img loading="lazy" src={ImagesService(this.blok.image.filename, true, { path: '696x392/smart/filters:format(jpeg):quality(60)'})} alt={this.blok.image.alt} width="696" height="392" />
                        </picture>
                        <div className="description">
                            <figcaption>{this.blok.caption}</figcaption>
                            <div className="copyright">{this.t("contentTypes.image.copyright")}: {this.blok.image.copyright}</div>
                        </div>
                    </figure>
                </div>
            </SbEditable>
        )
    }
}  
  
export default withTranslation()(Image)