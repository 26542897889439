import React, { Component } from "react"
import SbEditable from "storyblok-react"

import "./styles.scss"

import ImagesService from '../../../utils/images-service'


class Customers extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        return (
            <SbEditable content={this.blok}>
                <div className="customers-content">
                    <h2>{this.blok.headline}</h2>
                    <div className="customers">
                        {this.blok.customers.map((customer, index) => (
                        <a className="customer" key={index} href={customer.link.cached_url} target="_blank" rel="noreferrer" title={customer.name}>
                            <picture>
                                <source srcSet={`${ ImagesService(customer.logo.filename, true, { path: '228x128/smart/filters:format(webp)'}) } 1x, ${ ImagesService(customer.logo.filename, true, { path: '456x256/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                                <source srcSet={`${ ImagesService(customer.logo.filename, true, { path: '228x128/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(customer.logo.filename, true, { path: '456x256/smart/filters:format(jpeg):quality(60)'}) } 2x`} type="image/jpeg" />
                                <img loading="lazy" src={ImagesService(customer.logo.filename, true, { path: '228x128/smart/filters:format(jpeg):quality(60)'})} alt={customer.logo.alt} width="228" height="128" />
                            </picture>
                        </a>
                        ))}
                    </div>
                </div>
            </SbEditable>
        )
    }
}  
  
export default Customers