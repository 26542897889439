import React, { Component } from "react"
import SbEditable from "storyblok-react"

class ManifestItem extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        return (
            <SbEditable content={this.blok}>
                {this.blok.is_statement &&
                <div className="item is-statement"><span>{this.blok.text}</span></div>
                }
                {!this.blok.is_statement &&
                <div className="item"><span>{this.blok.text}</span></div>
                }
            </SbEditable>
        )
    }
}  
  
export default ManifestItem