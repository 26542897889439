import React, { Component } from "react"
import SbEditable from "storyblok-react"
import ImagesService from '../../../utils/images-service'

import "./styles.scss"

import { withTranslation } from "react-i18next"


class Image extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
        this.t = props.t
    }

    render() {
        return (
            <SbEditable content={this.blok}>
                <div className="illustration-content">
                    <img loading="lazy" src={ImagesService(this.blok.image.filename, false)} alt={this.blok.image.alt} />
                </div>
            </SbEditable>
        )
    }
}  
  
export default withTranslation()(Image)