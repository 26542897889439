import React from "react"
import DynamicComponent from "../../DynamicComponent"
import SbEditable from "storyblok-react"

import "./styles.scss"

const Column = ({blok}) => (
  <SbEditable content={blok} key={blok._uid}>
        {blok.mobile_full_width &&
        <div className={`col ${ blok.size} mobile-full`}>
            {blok.content.map((blok) => (
            <DynamicComponent key={blok._uid} blok={blok} />
            ))}
        </div>
        }
        {!blok.mobile_full_width && !blok.hide_on_mobile &&
        <div className={`col ${ blok.size }`}>
            {blok.content.map((blok) => (
            <DynamicComponent key={blok._uid} blok={blok} />
            ))}
        </div>
        }
        {!blok.mobile_full_width && blok.hide_on_mobile &&
        <div className={`col ${ blok.size } hide-on-mobile`}>
            {blok.content.map((blok) => (
            <DynamicComponent key={blok._uid} blok={blok} />
            ))}
        </div>
        }
  </SbEditable>
)

export default Column